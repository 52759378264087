<template>
  <div class="wrapper-container">
    <div class="describe-container-box">
      <div class="title">新品牌</div>
      <div class="sub-title">品牌商数智化技术产品&解决方案</div>
    </div>
    <img class="large-image-h360" src="@/image/brand/img_banner_brand@3x.png" />
    <div class="describe-container-box">
      <div class="describe">
        适用于全球厂牌、中国厂牌、区域厂牌的制造商、代理商、直营商等业态的企业。
      </div>
      <div class="introduce">
        全球蛙依托自身数智技术打造S2B2B平台、S2B2b2C平台、B2b2C平台等数智化产品，全面构建协同最高效的
        “ 美蛙品牌数智化业务平台 ”
        ，同时围绕技术、数据、运营、营销、供应链、造品六大能力，为品牌商和经销商企业提供全域全渠道数智化系统、定制化私域运营、大数据分析、支付+品牌双营销、渠道拓展、新品打造等多元服务，助力品牌商、经销商实现流通效率提升，业绩增长！
      </div>
    </div>
    <div
      class="describe-container-box"
      @click="$router.push('/brand/service-platform')"
    >
      <div
        class="sub-title"
        style="line-height: 22px; color: #222; font-size: 20px"
      >
        美蛙品牌数智化业务平台
      </div>
      <div
        class="describe"
        style="
          margin: 5px 0;
          line-height: 22px;
          color: #999;
          font-size: 14px;
          font-family: PingFang SC-Regular;
        "
      >
        五大产品实现全场景应用
      </div>
      <div
        class="programme-item-detail"
        style="margin-bottom: 20px; line-height: 17px; margin-top: 0"
      >
        <span>了解更多</span>
        <img src="@/image/homeContainer/btn_more_home@3x.png" alt="" />
      </div>
    </div>
    <div class="business-container">
      <img v-for="src in businessImages" :key="src" :src="src" alt="" />
    </div>
    <div class="func-module-container">
      <div class="func-title">六大赋能合力助攻</div>
      <div class="func-sub-title">让品牌商全域全渠道新增长</div>
    </div>
    <div class="large-image-container">
      <img
        class="large-image-h266"
        v-for="func in funcModules"
        :src="func.src"
        :key="func.path"
        @click="$router.push(func.path)"
      />
    </div>
    <div class="enable-container">
      <div class="enable-tatile">联创联销·价值共创</div>
      <div class="enable-sub-title">助力品牌商全域全渠道倍数增长</div>
      <div class="enable-describe">
        全球蛙作为科技零售产业互联网平台，多年来始终坚持探索数字技术对传统产业全方位全链条的改造策略，通过迭代升级品牌商“三融五通六赋能”全域全渠道数智化解决方案，以技术、数据、运营、营销、供应链、造品六大能力为抓手，加强供需两端数智互联，数据驱动供需两端融合发展，融合创新品牌商新生态，打造共生共赢新机制，实现生产、分配、流通、消费等各环节的数实融合新发展，实现高质量新增长！
      </div>
      <div class="enable-image-container">
        <div class="enable-image-title">联手知名品牌·共创亿元爆品</div>
        <div class="enable-image-scroll-view">
          <div
            class="enable-image-box"
            v-for="(srcs, srcsIndex) in enableImages"
            :key="srcsIndex"
          >
            <img
              class="small-image-h224"
              v-for="src in srcs"
              :src="src"
              :key="src"
            />
          </div>
        </div>
        <div class="pour">注：全球蛙已合作品牌商（部分）</div>
      </div>
    </div>
    <contact-us />
    <action-sheet :menu="enumerableRoutes" />
  </div>
</template>

<script>
import enumerableRoutesMixin from "@/mixins/enumerableRoutes";
export default {
  mixins: [enumerableRoutesMixin],
  data: () => ({
    funcModules: [
      {
        path: "/brand/technical-power",
        src: require("@/image/brand/img_unify_brand@3x.png"),
      },
      {
        path: "/brand/data-power",
        src: require("@/image/brand/img_sale_brand@3x.png"),
      },
      {
        path: "/brand/operate-power",
        src: require("@/image/brand/img_region_brand@3x.png"),
      },
      {
        path: "/brand/marketing-power",
        src: require("@/image/brand/img_market_brand@3x.png"),
      },
      {
        path: "/brand/commodity-power",
        src: require("@/image/brand/img_commodity_brand@3x.png"),
      },
      {
        path: "/brand/explosives-power",
        src: require("@/image/brand/img_explosives_brand@3x.png"),
      },
    ],
    enableImages: [
      [
        require("@/image/brand/img_bj_brand.png"),
        require("@/image/brand/img_dior_brand.png"),
        require("@/image/brand/img_dl_brand.png"),
        require("@/image/brand/img_durex_brand.png"),
        require("@/image/brand/img_flm_brand.png"),
        require("@/image/brand/img_ww_brand.png"),
      ],
      [
        require("@/image/brand/img_ht_brand.png"),
        require("@/image/brand/img_hy_brand.png"),
        require("@/image/brand/img_jly_brand.png"),
        require("@/image/brand/img_jn_brand.png"),
        require("@/image/brand/img_ksf_brand.png"),
        require("@/image/brand/img_xh_brand.png"),
      ],
      [
        require("@/image/brand/img_lb_brand.png"),
        require("@/image/brand/img_lh_brand.png"),
        require("@/image/brand/img_lk_brand.png"),
        require("@/image/brand/img_md_brand.png"),
        require("@/image/brand/img_mn_brand.png"),
        require("@/image/brand/img_xxxy_brand.png"),
      ],
      [
        require("@/image/brand/img_pp_brand.png"),
        require("@/image/brand/img_qf_brand.png"),
        require("@/image/brand/img_ryytn_brand.png"),
        require("@/image/brand/img_sh_brand.png"),
        require("@/image/brand/img_ttl_brand.png"),
        require("@/image/brand/img_yl_brand.png"),
      ],
    ],
    businessImages: [
      require("@/image/brand/img_s2b2b_brand@3x.png"),
      require("@/image/brand/img_s2b2b2c_brand@3x.png"),
      require("@/image/brand/img_b2b2c_brand@3x.png"),
      require("@/image/brand/img_universe_brand@3x.png"),
      require("@/image/brand/img_oms_brand@3x.png"),
    ],
  }),
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/solution.common.scss";
.large-image-h360 {
  margin: 60px 0;
}
.large-image-container {
  padding: 0 !important;
  .large-image-h266 {
    margin-bottom: 40px;
  }
  .large-image-h266:last-of-type {
    margin-bottom: 50px;
  }
}
.enable-image-container {
  margin-top: 60px;
  border-radius: 16px;
  box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.1),
    0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  padding-bottom: 28px;
  box-sizing: border-box;
  max-height: 2460px;
  .enable-image-title {
    text-align: center;
    width: 400px;
    height: 56px;
    line-height: 56px;
    margin: 0 auto;
    color: $textColorfff;
    font-size: 26px;
    font-family: PingFang SC, PingFang SC-Semibold;
    font-weight: 600;
    background: linear-gradient(90deg, #0578d4, #0fe198);
    border-radius: 0px 0px 28px 28px;
    margin-bottom: 66px;
  }
  .enable-image-scroll-view {
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    &::-webkit-scrollbar {
      display: none;
      width: 0px;
      height: 0px;
    }
    .enable-image-box {
      display: flex;
      flex-flow: column;
      .small-image-h224 {
        margin-bottom: 48px;
      }
    }
  }
}
.func-module-container {
  padding-bottom: 40px !important;
}
.business-container {
  margin-bottom: 40px;
  img {
    width: 100%;
    margin-bottom: 20px;
  }
  img:last-of-type {
    margin-bottom: 0px;
  }
}
.introduce {
  color: $textColor999 !important;
  margin-bottom: 60px !important;
}
</style>
